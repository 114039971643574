import { type CartFragmentPayload } from './fragments/cart.graphql'


export type ModifiedCart = ReturnType<typeof modifyCart>

const modifyCart = (cart: CartFragmentPayload) => {
  const productsCount = cart.products?.reduce((result, { quantity }) => result + quantity, 0) || 0
  const limitedDropsCount = cart.limitedDropItems?.reduce((result, { isPromo, quantity }) => isPromo ? result : result + quantity, 0) || 0

  const totalCount = (
    productsCount
    + limitedDropsCount
    + (cart.subscription ? 1 : 0)
    + (cart.giftSubscription ? 1 : 0)
    + (cart.giftCard ? 1 : 0)
    // TODO uncomment if queue items are inside ALL purchase level - added on 2021-10-13 by maddoger
    // + cart.queueItems?.length
  )

  const hasSubscription = Boolean(cart.subscription)
  const hasProducts = cart.products?.length > 0
  const hasLimitedDrops = cart.limitedDropItems?.length > 0
  const hasGiftSubscription = Boolean(cart.giftSubscription)
  const hasGiftCard = Boolean(cart.giftCard)
  const hasQueueItems = cart.queueItems?.length > 0

  const countByFutureDiscount: Record<string, number> = {}

  cart.products?.forEach(({ futureDiscounts, quantity }) => {
    futureDiscounts?.forEach((item) => {
      countByFutureDiscount[item.key] = (countByFutureDiscount[item.key] || 0) + quantity
    })
  })

  const isEmpty = totalCount === 0

  let subTotal = cart.subTotal

  if (cart.subscription?.coupon?.code === 'NO_CHARGE') {
    subTotal -= cart.subscription.price
  }

  return {
    ...cart,
    subTotal,
    totalCount,
    hasSubscription,
    hasProducts,
    hasLimitedDrops,
    hasGiftSubscription,
    hasGiftCard,
    hasQueueItems,
    isEmpty,
    countByFutureDiscount,
  }
}


export default modifyCart
